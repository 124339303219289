<template>
    <AppOnePageDropdown
        v-model="value"
        :itemSize="itemSize"
        :loading="loading"
        :renderOption="(slotProps) => slotProps.option?.nome"
        :renderValue="(slotProps) => slotProps.value?.nome"
        :service="$service"
        optionLabel="nome"
        placeholder="Selecione o estado..."
        @change="change"
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '@/services/BaseService';

export default {
    name: 'DropdownEstado',
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 50
        },
        change: {
            type: Function
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null,
            loading: false
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/estados');
    }
};
</script>
