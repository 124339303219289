<template>
    <AppPaginatedGridNested
        ref="gridEspecialidades"
        :service="service"
        parentName="profissional-sesmt"
        :defaultProps="{ profissionalSesmtId: this.$route.params.id }"
        :expander="false"
        labelBotaoAdicionarItem="Responsabilidade"
        :isValid="isValid"
        :disableSave="isInativo"
        :showButtonAlterar="true"
        :queryParams="queryParams"
        @onAfterSave="onAfterSave"
        @onAfterLoad="onAfterLoad"
    >
        <template #columns>
            <Column field="produtosServico" header="Produtos e Serviços">
                <template #editor="{ data, field }">
                    <DropdownProdutosServicos v-model="data[field]" @update:modelValue="data.produtosServicoId = data[field]?.id" />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.produtosServico?.nome }}
                </template>
            </Column>
            <Column field="responsavel" header="Responsavel">
                <template #editor="{ data, field }">
                    <InputSwitch v-model="data[field]" @change="validarEdicaoResponsavel(data)" />
                </template>
                <template #body="slotProps">
                    {{ formatarResponsavel(slotProps?.data?.responsavel) }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.createdAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import NestedServiceSesmt from '../../../services/NestedServiceSesmt';
import DropdownProdutosServicos from '../../produtos-servicos/components/DropdownProdutosServicos.vue';
export default {
    props: {
        active: Number,
        habilitaEdicaoColunaResponsavel: Boolean
    },
    components: { DropdownProdutosServicos },
    emits: ['desabilitarAbas', 'habilitarAbas', 'onGetResponsavelEspecialidade'],
    data() {
        return {
            form: {},
            isValid: true,
            submitted: false,
            isInativo: false
        };
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.form.ativo = true;
    },
    created() {
        this.service = new NestedServiceSesmt('/profissionais-sesmt-produtos-servicos');
    },
    computed: {
        queryParams() {
            return {
                profissionalSesmtId: this.$route.params.id
            };
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.form = data;
        },

        formatarResponsavel(responsavel) {
            if (responsavel === true) {
                return 'Sim';
            }
            return 'Não';
        },

        validarEdicaoResponsavel(data) {
            if (!this.habilitaEdicaoColunaResponsavel) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Você precisa ser responsavel para poder alterar este campo!',
                    life: 3000
                });
                return (data.responsavel = false);
            }
            return (this.form.responsavel = data);
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave(value) {
            this.isInativo = true;
            this.submitted = false;
            this.form = value;
            await this.$refs.gridEspecialidades.load();
            this.$emit('habilitarAbas');
        },
        async onAfterLoad(records) {
            const isResponsavelEspecialidade = !!records.find((p) => p.responsavel);
            this.$emit('onGetResponsavelEspecialidade', isResponsavelEspecialidade);
        }
    }
};
</script>
