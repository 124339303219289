import SesmtService from './SesmtService';
import { getClientSesmt } from './http-sesmt'

export default class NestedService extends SesmtService {
    constructor(path) {
        super(path)

        this.path = path;
    }

    findByOrigem({ page = 1, limit = 10, filter = '', sort = '', ...otherParams }, parentName) {
        return getClientSesmt().get(`${this.path}/find-by-origem/${parentName}`, {
            params: {
                page,
                limit,
                filter,
                sort,
                ...otherParams
              }
        });
    }

    findByParent(parentName, idParent) {
        return getClientSesmt().get(`${this.path}/find-by-${parentName}/${idParent}`)
    }
}