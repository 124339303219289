const EnumTipoContratacao = Object.freeze(
    [
        {
            value: 'INTERNO',
            label: 'Interno'
        },
        {
            value: 'EXTERNO',
            label: 'Externo'
        }
    ]
  )
  
  export default EnumTipoContratacao;