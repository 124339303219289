const ClassificaoTipoServicoEnum = Object.freeze(
    [
        {
            value: 'SAUDE',
            label: 'Saúde'
        },
        {
            value: 'SEGURANCA',
            label: 'Segurança'
        },
        {
            value: 'SAUDE E SEGURANCA',
            label: 'Saúde e Segurança'
        }
    ]
  )
  
  export default ClassificaoTipoServicoEnum;
  