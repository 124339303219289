<template>
    <AppPaginatedGridNested
        :service="service"
        parentName="profissional-sesmt"
        :defaultProps="{ profissionalSesmtId: this.$route.params.id }"
        :expander="false"
        labelBotaoAdicionarItem="Cliente"
        :isValid="isValid"
        :showButtonAlterar="true"
        :queryParams="queryParams"
    >
        <template #columns>
            <Column field="customer" header="Cliente">
                <template #editor="{ data, field }">
                    <DropdownCliente v-model="data[field]" @update:modelValue="data.customerId = data[field]?.id" />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.customer?.name }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.createdAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import DropdownCliente from '../../clientes/components/DropdownCliente.vue';
import NestedServiceSesmt from '../../../services/NestedServiceSesmt';
export default {
    emits: ['desabilitarAbas', 'habilitarAbas'],
    components: {
        DropdownCliente
    },
    data() {
        return {
            formulario: {},
            isValid: true,
            submitted: false,
            isInativo: false
        };
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.formulario.ativo = true;
    },
    created() {
        this.service = new NestedServiceSesmt('/profissionais-sesmt-customers');
    },
    computed: {
        queryParams() {
            return {
                profissionalSesmtId: this.$route.params.id
            };
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.formulario = data;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave(response) {
            this.isInativo = true;
            this.submitted = false;
            this.formulario = response.data;
            this.$emit('habilitarAbas');
        },
        habilitarEdicao() {
            this.$emit('desabilitarAbas');
            this.isInativo = false;
        },
        cancelarEdicao(record) {
            this.isInativo = true;
            this.formulario = JSON.parse(JSON.stringify(record));
            this.$emit('habilitarAbas');
        }
    }
};
</script>
