import axios from 'axios';
import { getIdsAuthorizedCustomers, getIdsAuthorizedCustomersBranches, logout } from './auth';
import { getFilterCustomerIds } from './filters';
import { getTenant, getToken } from '@/common/storage';

export function getClientSesmt(auxHeaders = {}) {
    return getClient(process.env.VUE_APP_API_SESMT_URL, auxHeaders);
}

export function getClientAreaMedica(auxHeaders = {}) {
    return getClient(process.env.VUE_APP_API_MEDICO_URL, auxHeaders);
}

export function getClientSecurity(auxHeaders = {}) {
    return getClient(process.env.VUE_APP_API_SEGURANCA_URL, auxHeaders);
}

function getClient(baseURL, auxHeaders = {}) {
    const idsAuthorizedCustomers = getIdsAuthorizedCustomers();
    const idsAuthorizedBranches = getIdsAuthorizedCustomersBranches();
    const filteredCustomerIds = getFilterCustomerIds();
    const filterCustomerIds = filteredCustomerIds ? { 'filter-customer': JSON.stringify(filteredCustomerIds) } : {};

    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const apiKey = getTenant() ? { 'x-api-key': getTenant() } : {};
    const customersKeys = idsAuthorizedCustomers ? { customers: JSON.stringify(idsAuthorizedCustomers) } : {};
    const customersBranchKeys = idsAuthorizedBranches ? { 'customer-branches': JSON.stringify(idsAuthorizedBranches) } : {};
    const headers = { ...authToken, ...apiKey, ...auxHeaders, ...customersKeys, ...filterCustomerIds, ...customersBranchKeys };

    const client = axios.create({ baseURL, headers });

    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            if (error?.response?.data?.details?.response?.semApiKey) {
                const event = new Event('sem-api-key');
                document.dispatchEvent(event);
            }

            return Promise.reject(error);
        }
    );

    return client;
}