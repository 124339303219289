<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/profissionais-sesmt">
        <template #content>
            <div class="card">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="profissional-list" />
                    <h3 class="ml-1">{{ title }}</h3>
                </div>
                <TabView :scrollable="true" ref="tabview" :activeIndex="active" @tab-click="onTabClick">
                    <TabPanel header="Profissional" :disabled="false" :key="0">
                        <Profissional
                            :active="active"
                            @desabilitarAbas="desabilitarAbas"
                            @habilitarAbas="habilitarAbas"
                            @onResponsavel="setIsResponsavel"
                            :isResponsavelEspecialidade="isResponsavelEspecialidade"
                        />
                    </TabPanel>
                    <TabPanel header="Especialidades" :disabled="false" :key="1">
                        <Especialidades
                            ref="gridEspecialidades"
                            :active="active"
                            @desabilitarAbas="desabilitarAbas"
                            @habilitarAbas="habilitarAbas"
                            @onGetResponsavelEspecialidade="setResponsavelEspecialidade"
                            :habilitaEdicaoColunaResponsavel="isResponsavel"
                        />
                    </TabPanel>
                    <TabPanel header="Clientes" :disabled="false" :key="2">
                        <ClientesGrid :active="active" @desabilitarAbas="desabilitarAbas" @habilitarAbas="habilitarAbas" />
                    </TabPanel>
                </TabView>
            </div>
        </template>
    </AppPanel>
</template>
]]
<script>
import { getCurrentCompany } from '../../services/store';
import Profissional from './components/Profissional.vue';
import Especialidades from './components/Especialidades.vue';
import ClientesGrid from './components/ClientesGrid.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            isResponsavelEspecialidade: false,
            isResponsavel: false,
            active: 0
        };
    },
    components: {
        Profissional,
        Especialidades,
        ClientesGrid,
        AppInfoManual
    },
    mounted() {
        if (!this.$route.params.id) this.desabilitarAbas();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editando profissional sesmt' : 'Adicionando profissional sesmt';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        onTabClick(event) {
            this.active = event.index;
        },
        setIsResponsavel(isResponsavel) {
            return (this.isResponsavel = isResponsavel);
        },
        setResponsavelEspecialidade(isResponsavelEspecialidade) {
            this.isResponsavelEspecialidade = isResponsavelEspecialidade;
        },
        habilitarAbas() {
            this.$refs.tabview.tabs.filter((item) => item.key != this.active).forEach((item) => (item.props.disabled = false));
            this.$refs.tabview.$forceUpdate();
        },
        desabilitarAbas() {
            this.$refs.tabview.tabs.filter((item) => item.key != this.active).forEach((item) => (item.props.disabled = true));
            this.$refs.tabview.$forceUpdate();
        }
    }
};
</script>
