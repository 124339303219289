const EnumOrgaoClasse = Object.freeze(
    [
        {
            value: 1,
            label: 'Conselho Regional de Medicina - CRM'
        },
        {
            value: 4,
            label: 'Conselho Regional de Engenharia e Agronomia - CREA'
        },
        {
            value: 9,
            label: 'Outros'
        }
    ]
  )
  
  export default EnumOrgaoClasse;