<template>
    <AppFormCadastroNested
        :service="service"
        :form="form"
        :disableSave="isInativo"
        backPath="/profissionais-sesmt"
        @onBeforeSave="onBeforeSave"
        @onAfterSave="onAfterSave"
        @onLoadDataEdit="onLoadDataEdit"
        @onValidate="onValidate"
        @onHabilitarEdicao="habilitarEdicao"
        @onCancelarEdicao="cancelarEdicao"
    >
        <template #content>
            <h5 class="mb-4">Identificação</h5>
            <div class="flex flex-column">
                <div class="flex flex-row">
                    <div class="field mr-2 col-6">
                        <label for="nome">Nome</label>
                        <InputText
                            id="nome"
                            required="true"
                            v-model="form.nome"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nome }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                    </div>
                    <div class="field mr-2 col-6">
                        <label for="cpf">CPF</label>
                        <InputMask
                            id="cpf"
                            v-model="form.cpf"
                            mask="999.999.999-99"
                            placeholder="Informe seu CPF"
                            :class="{ 'p-invalid': submitted && !form.cpf }"
                            :disabled="isInativo"
                            :unmask="true"
                        />
                        <small class="p-error" v-if="submitted && !form.cpf">CPF é obrigatório.</small>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="field mr-2 col-6">
                        <label for="classificacao">Classificação</label>
                        <Dropdown
                            id="classificacao"
                            v-model="form.classificacao"
                            :options="enumTiposClassificacao"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            :class="{ 'p-invalid': submitted && !form.classificacao }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.classificacao">Classificação é obrigatório.</small>
                    </div>
                    <div class="field mr-2 col-6">
                        <label for="tipo">Tipo contratação</label>
                        <Dropdown
                            id="tipo"
                            v-model="form.tipo"
                            :options="enumTiposContratacao"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            :class="{ 'p-invalid': submitted && !form.tipo }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.tipo">Tipo contratação é obrigatório.</small>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="field mr-2 col-6">
                        <label for="usuario">Usuário</label>
                        <DropdownUsuario id="usuario" v-model="form.usuarioVinculado" :disabled="isInativo" />
                    </div>
                </div>
            </div>

            <hr />

            <h5 class="mb-4">Profissional</h5>
            <div class="flex flex-column">
                <div class="flex flex-row">
                    <div class="field mr-2 col-6">
                        <label for="orgaoClasse">Órgão de Classe</label>
                        <Dropdown
                            id="orgaoClasse"
                            v-model="form.orgaoClasse"
                            :options="enumTiposOrgaoClasse"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecione..."
                            @change="onChangeOrgaoClasse"
                            :class="{ 'p-invalid': submitted && !form.orgaoClasse }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.orgaoClasse">Órgão de Classe é obrigatório.</small>
                    </div>
                    <div class="field mr-2 col-6">
                        <label for="numeroInscricaoOrgaoClasse">Número de inscrição do órgão de classe</label>
                        <InputText
                            id="numeroInscricaoOrgaoClasse"
                            v-model="form.numeroInscricaoOrgaoClasse"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.numeroInscricaoOrgaoClasse }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.numeroInscricaoOrgaoClasse"
                            >Número de inscrição do órgão de classe é obrigatório.</small
                        >
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="field mr-2 col-6">
                        <label for="orgaoClasseOutros">Órgão de Classe (Outros)</label>
                        <InputText
                            id="orgaoClasseOutros"
                            v-model="form.orgaoClasseOutros"
                            required="false"
                            autofocus
                            autocomplete="off"
                            :disabled="orgaoClasseOutrosDisabled || isInativo"
                            :class="{ 'p-invalid': submitted && !form.orgaoClasseOutros && !orgaoClasseOutrosDisabled }"
                        />
                        <small class="p-error" v-if="submitted && !form.orgaoClasseOutros && !orgaoClasseOutrosDisabled"
                            >Necessario informar Órgão de Classe (Outros)</small
                        >
                    </div>
                    <div class="field mr-2 col-6">
                        <label for="ufOrgaoClasse">UF do órgão de classe</label>
                        <DropdownEstado
                            id="ufOrgaoClasse"
                            v-model="form.ufOrgaoClasse"
                            @update:modelValue="form.ufOrgaoClasseId = form.ufOrgaoClasse?.id"
                            :class="{ 'p-invalid': submitted && !form.ufOrgaoClasse }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.ufOrgaoClasse">UF do órgão de classe é obrigatório.</small>
                    </div>
                </div>
            </div>
            <div class="field field-checkbox col-6 mb-0 pb-0">
                <label class="mr-2 ml-0" for="responsavel">Responsável</label>
                <InputSwitch :disabled="isResponsavelEspecialidade || isInativo" id="responsavel" v-model="form.responsavel" />
            </div>
            <div class="field col-12 pt-0">
                <Message v-if="isResponsavelEspecialidade" :closable="false">
                    <div class="flex-column">
                        <p class="p-0 m-0">
                            Para que seja possível desmarcar a opção, é necessário remover todas as responsabilidades na aba especialidades.
                        </p>
                    </div>
                </Message>
            </div>
            <div>
                <hr />
                <div class="text-800 mb-4 flex align-items-center">
                    <span class="text-primary pi pi-info-circle mr-2" v-tooltip="'Obrigatório para responsáveis'" />
                    <div>
                        <h5 style="margin: auto">Endereço</h5>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="field mr-2 col-1">
                        <label for="cep">CEP</label>
                        <InputMask
                            id="cep"
                            v-model="form.cep"
                            mask="99999-999"
                            :class="{ 'p-invalid': submitted && !form.cep && form.responsavel }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.cep && form.responsavel">CEP é obrigatório.</small>
                    </div>
                    <div class="field mr-2 col-6">
                        <label for="logradouro">Logradouro</label>
                        <InputText
                            id="logradouro"
                            v-model="form.logradouro"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.logradouro && form.responsavel }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.logradouro && form.responsavel">Logradouro é obrigatório.</small>
                    </div>
                    <div class="field mr-2 col-1">
                        <label for="numero">Número</label>
                        <InputText
                            id="numero"
                            v-model="form.numero"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.numero && form.responsavel }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.numero && form.responsavel">Número é obrigatório.</small>
                    </div>
                    <div class="field mr-2 col-4">
                        <label for="bairro">Bairro</label>
                        <InputText
                            id="bairro"
                            v-model="form.bairro"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.bairro && form.responsavel }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.bairro && form.responsavel">Bairro é obrigatório.</small>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="field mr-2 col-6">
                        <label for="complemento">Complemento</label>
                        <InputText id="complemento" v-model="form.complemento" required="false" autofocus autocomplete="off" :disabled="isInativo" />
                    </div>
                    <div class="field mr-2 col-3">
                        <label for="cidade">Cidade</label>
                        <DropdownCidade
                            id="cidade"
                            v-model="form.cidade"
                            :class="{ 'p-invalid': submitted && !form.cidade && form.responsavel }"
                            :disabled="isInativo"
                        />
                        <small class="p-error" v-if="submitted && !form.cidade && form.responsavel">Cidade é obrigatório.</small>
                    </div>
                </div>
            </div>
        </template>
    </AppFormCadastroNested>
</template>

<script>
import SesmtService from '../../../services/SesmtService';
import ClassificaoTipoServicoEnum from '../../../enums/ClassificaoTipoServicoEnum';
import EnumOrgaoClasse from '../../../enums/EnumOrgaoClasse';
import EnumTipoContratacao from '../../../enums/EnumTipoContratacao';
import DropdownCidade from '../../cidades/components/DropdownCidade.vue';
import DropdownUsuario from '../../usuarios/components/DropdownUsuario.vue';
import DropdownEstado from '../../estados/components/DropdownEstado.vue';
export default {
    props: {
        active: Number,
        isResponsavelEspecialidade: Boolean
    },
    emits: ['desabilitarAbas', 'habilitarAbas', 'onResponsavel'],
    components: {
        DropdownCidade,
        DropdownUsuario,
        DropdownEstado
    },
    data() {
        return {
            form: {
                responsavel: false,
                usuarioVinculadoId: null,
                cidadeId: null
            },
            enumTiposClassificacao: ClassificaoTipoServicoEnum,
            enumTiposContratacao: EnumTipoContratacao,
            enumTiposOrgaoClasse: EnumOrgaoClasse,
            isInativo: false,
            service: null,
            submitted: false
        };
    },
    created() {
        this.service = new SesmtService('/profissionais-sesmt');
    },
    mounted() {
        this.isInativo = !!this.$route.params.id;
        this.form.ativo = true;
    },
    computed: {
        orgaoClasseOutrosDisabled() {
            if (this.form.orgaoClasse === this.enumTiposOrgaoClasse.find(({ label }) => label === 'Outros').value) {
                return false;
            }
            return true;
        }
    },
    watch: {
        'form.usuarioVinculado'(newValue) {
            this.form.usuarioVinculadoId = newValue?.id;
        },
        'form.cidade'(newValue) {
            this.form.cidadeId = newValue?.id;
        },
        'form.responsavel'() {
            this.$emit('onResponsavel', this.form?.responsavel);
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave(response) {
            this.isInativo = true;
            this.submitted = false;
            this.form = response.data;
            this.$emit('habilitarAbas');
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (this.validarOrgaoClasseOutros() || this.validarNaoTemEndereco()) {
                return true;
            }
        },
        validarOrgaoClasseOutros() {
            if (this.enumTiposOrgaoClasse.find(({ label }) => label === 'Outros').value === this.form.orgaoClasse && !this.form.orgaoClasseOutros)
                return true;
        },
        validarNaoTemEndereco() {
            if (this.form.responsavel && (!this.hasCep() || !this.hasLogradouro() || !this.hasNumero() || !this.hasBairro() || !this.hasCidade())) {
                return true;
            }
        },
        hasCep() {
            return this.form.cep !== '' ? true : false;
        },
        hasLogradouro() {
            return this.form.logradouro ? true : false;
        },
        hasNumero() {
            return this.form.numero ? true : false;
        },
        hasBairro() {
            return this.form.bairro ? true : false;
        },
        hasCidade() {
            return this.form.cidadeId ? true : false;
        },
        habilitarEdicao() {
            this.$emit('desabilitarAbas');
            this.isInativo = false;
        },
        cancelarEdicao(record) {
            this.isInativo = true;
            this.form = JSON.parse(JSON.stringify(record));
            this.$emit('habilitarAbas');
        },
        onChangeOrgaoClasse(event) {
            if (event.value !== this.enumTiposOrgaoClasse.find(({ label }) => label === 'Outros').value) {
                this.form.orgaoClasseOutros = null;
            }
        }
    }
};
</script>

<style>
</style>
